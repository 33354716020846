#manualStoreDialog {
  fieldset {
    border: solid 1px #c4c4c4;
    border-radius: 0px;
  }
  #storeValue::placeholder {
    color: $placeholder-color;
  }
  .manualTitle {
    display: flex;
    flex-direction: row;
    background-color: #9b9b9b;
    color: $black;
    padding: 10px 0px;
    text-transform: uppercase;
    .manualText {
      justify-content: left;
      flex-grow: 1;
      font-family: "AutoZone Medium";
      padding-left: 16px;
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: condensed;
    }
    .closeBtn {
      color: $black;
      margin: 2px 14px 2px 0px;
      font-size: 12px;
      font-weight: bold;
    }
    .closeIcon {
      width: 0.75em;
      font-weight: bold;
      margin-top: -3px;
      cursor: pointer;
    }
  }
  .manualBody {
    margin-bottom: 16px;
    padding-left: 16px;
    color: $black;
    display: flex;
    flex-direction: column;
    font-size: 12px !important;
    font-family: Arial;
    font-size: 14px;
    #bodyLine1 {
      margin-top: 16px;
      #bodyLabel {
        font-size: 14px !important;
      }
    }
    #bodyLine2 {
      div {
        label[data-shrink="false"] {
          transform: translate(14px, 10px) scale(0.9);
          color: #c4c4c4;
        }
        label {
          font-family: Arial !important;
          font-size: 14px !important;
          color: $black;
          padding-top: 1px;
        }
        input {
          width: 179px;
          height: 38px;
          padding: 0px 12px;
          font-size: 14px !important;
        }
      }
    }
  }
  .manualActionsWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px !important;
    .btnCancel {
      button {
        color: $black !important;
        border: 1px solid $black;
        background-color: $white;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 6px 23px;
      }
    }
    .btnSubmit {
      button {
        color: $white !important;
        border: 1px solid $primary-orange;
        background-color: $primary-orange;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0;
        padding: 6px 23px;
      }
    }
    button {
      text-transform: uppercase;
    }
  }
}
