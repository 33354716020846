@import "./Variables.scss";
.listItem {
  background-color: #e9e9e9 !important;
  display: flex;
  cursor: pointer;
  padding: 12px 12px !important;
  border-radius: 0px !important;
}

.listItemWithScroll {
  background-color: #e9e9e9 !important;
  display: flex;
  cursor: pointer;
  padding: 11px 10px 11px 15px !important;
  border-radius: 0px !important;
}

.inline-block {
  display: inline-block;
}
.wi-100 {
  width: 100%;
}
.checkCircle {
  margin: 4px 12px 4px 8px;
}

.custName {
  text-transform: uppercase;
  font-family: "AutoZone Medium";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
  margin-left: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.organinzationMultiple {
  div {
    display: block !important;
  }
}

.custNameTitle {
  text-transform: uppercase;
  font-family: "AutoZone Medium";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
  list-style-type: none;
  margin-left: 0px;
  position: relative;
  padding-left: 0;
  margin-bottom: 7px;
}
.custNameTitleDecoration {
  padding-left: 0px !important;
}
.custNameTitleDecoration:before {
  width: 0px !important;
  height: 0px !important;
  border: 0px solid #000 !important;
  top: 0 !important;
}
.redBorder .custNameTitle {
  padding-left: 15px;
}
.redBorder .custNameTitle::before {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  border: 2px solid #000;
}
.checkCircle-wrapper div ul {
  margin-left: 0px;
  padding-left: 0px;
}
.checkCircle-wrapper .float-left + div {
  display: inline-block;
}

[class^="nGkxT"],
[class*=" nGkxT"] {
  position: relative !important;
}
.custNameList {
  margin-left: 20px;
}

.dateTime {
  color: $black;
  text-transform: uppercase;
  float: right;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.totalPieces {
  color: $black;
  font-family: "AutoZone";
  font-size: 16px;
  align-items: center;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
  margin: 0px 0px 0px 12px;
  float: left;
  width: 33%;
  .totalPiecesCount {
    font-family: "AutoZone Medium";
    font-size: 18px !important;
    font-weight: 500;
    padding-left: 2px;
  }
}

.pickLocations {
  color: $black;
  text-transform: capitalize;
  text-align: left;
  float: right;
  align-items: center;
  font-family: "AutoZone";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  .pickLocationsCount {
    font-family: "AutoZone Medium";
    font-size: 18px !important;
    font-weight: 500;
    padding-left: 2px;
  }
}

.titlePickTour {
  font-family: "Autozone Medium";
  font-size: 18px;
  font-weight: 900;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.deliveryStatus {
  padding-left: 10px;
  margin-left: 10px;
}

.urgent {
  float: right;
}

.successText {
  color: green;
}

.dangerText {
  color: $red;
}

.dangerTitle {
  color: #d52b1e;
  font-weight: bold;
}

.redBorder {
  border: solid 1px #d52b1e !important;
}

#CheckArrow {
  width: 20px;
  height: 20px;
  margin-top: -2.5px;
}

#SelectedCheckArrow {
  width: 20px;
  height: 20px;
  margin-top: -2.5px;
}

.selectedItem {
  background-color: #6893b3 !important;
}
.selectedItemDisabled {
  background-color: $white !important;
}
@media all and (min-width: 480px) {
  .list-group {
    padding-left: 3px !important;
  }
  .finalizationError {
    border-left: solid 1px #d52b1e !important;
    border-right: solid 1px #d52b1e !important;
    border-bottom: solid 1px #d52b1e !important;
  }
  .finalizationError:first-child {
    border-top: solid 1px #d52b1e !important;
  }
  .finalizationError {
    position: relative;
    background-color: #fff2f0 !important;
    #actionButtonForFinalization {
      display: flex;
      flex-direction: row;
      font-family: "AutoZone";
      align-items: center;
      font-weight: normal;
      font-style: normal;
      font-stretch: condensed;
      line-height: normal;
      letter-spacing: normal;
      text-transform: capitalize;
      & > div:nth-child(1) {
        width: 80%;
        display: flex;
        flex-direction: row;

        div {
          width: auto !important;
          margin: 0px 5px;
        }
      }
      .actionItemName {
        float: left;
        display: flex;
        height: 16px;
        font-family: Arial;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #231f20;
        margin: 3px 0px !important;
      }
      .btnFinalization {
        width: 20%;
        button {
          float: right;
          color: $primary-orange !important;
          border: 1px solid $primary-orange;
          background-color: #fff2f0 !important;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Heavy";
          font-style: normal;
          font-stretch: condensed;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          border-radius: 0;
          text-transform: uppercase;
          margin-top: 10px;
        }
      }
    }
    .finalizationErrorWrapper {
      display: flex;
      flex-direction: row;
      .finalizationErrorIcon {
        width: 16px;
        height: 16px;
      }
      .finalizationErrorMsg,
      .custNameTitle {
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #d52b1e;
        padding: 3px;
        margin: 3px;
      }
      .custNameTitle {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
@media all and (min-width: 480px) {
  .list-group {
    padding-left: 3px !important;
  }
}
@media all and (min-width: 480px) {
  .list-group {
    margin-left: 0px;
  }

  .itemWrapper {
    margin-left: 0.3%;
  }

  .priceWrapper {
    margin-top: 6px;
    display: flex;
  }

  .checkCircle-wrapper {
    margin-left: -15px;
  }
}

.finalizationErrorCloseButton {
  $buttonSize: 26px;
  position: absolute;
  right: 10px;
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  background: none;
  font-size: $buttonSize;
  line-height: $buttonSize;
  width: $buttonSize;
  padding: 0;
  top: 50%;
  margin-top: -$buttonSize / 2;
  cursor: pointer;

  &:hover {
    background-color: rgba($red, 0.7);
    color: $white;
  }
}

@media all and (max-width: 480px) {
  .finalizationError {
    border-left: solid 1px #d52b1e !important;
    border-right: solid 1px #d52b1e !important;
    border-bottom: solid 1px #d52b1e !important;
  }
  .finalizationError:first-child {
    border-top: solid 1px #d52b1e !important;
  }
  .finalizationError {
    background-color: #fff2f0 !important;

    #actionButtonForFinalization {
      display: flex;
      flex-direction: row;
      font-family: "AutoZone";
      align-items: center;
      font-weight: normal;
      font-style: normal;
      font-stretch: condensed;
      line-height: normal;
      letter-spacing: normal;
      text-transform: capitalize;
      & > div:nth-child(1) {
        width: 80%;
        display: flex;
        flex-direction: row;
      }
      .custmerNameAutoWidth {
        width: auto !important;
        margin: 0px 5px;
      }
      .custmerName {
        width: 130px !important;
        margin: 0px 5px;
      }
      .actionItemName {
        float: left;
        display: flex;
        height: 16px;
        font-family: Arial;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #231f20;
        margin: 3px 0px !important;
      }
      .btnFinalization {
        width: 20%;
        button {
          float: right;
          color: $primary-orange !important;
          border: 1px solid $primary-orange;
          background-color: #fff2f0 !important;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Heavy";
          font-style: normal;
          font-stretch: condensed;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          border-radius: 0;
          text-transform: uppercase;
          margin-top: 10px;
        }
      }
    }
    .finalizationErrorWrapper {
      display: flex;
      flex-direction: row;
      .finalizationErrorIcon {
        width: 16px;
        height: 16px;
      }
      .finalizationErrorMsg,
      .custNameTitle {
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #d52b1e;
        padding: 3px;
        margin: 3px;
      }
      .custNameTitle {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .store {
    width: 60% !important;
    height: 17px;
    font-family: AutoZone;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #d52b1e;
  }

  .organinzationMultiple {
    display: flex;
    flex-direction: column;
  }
  .itemWrapper {
    margin-left: 0px;
    margin-right: 0px;
  }

  .priceWrapper {
    margin-left: 2px;
    margin-top: 6px;
    display: flex;
    .totalPieces {
      width: 35%;
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      margin: 0px 0px 0px 12px;
    }
    .deliveryMethod {
      width: 29%;
      display: flex;
      justify-content: center;
      font-size: 16px;
    }
    .pickLocations {
      width: 36%;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      margin-right: -10px !important;
    }
    .pickLocations .pickLocationsCount {
      font-size: 18px;
    }
  }
  .checkCircle-wrapper {
    margin-left: -10px;
  }
  .checkCircle {
    margin: 5px 10px 5px 8px;
  }
  // .custNameTitle { font-size:14px; }
  .redBorder .custNameTitle::before {
    width: 7px;
    height: 7px;
    border: 1px solid #000;
    top: 10px;
  }

  .urgent.dangerTitle {
    font-size: 15px;
  }
  .checkCircle-wrapper .dateTime {
    font-size: 16px;
    padding-top: 5px;
  }
}

.deliver {
  color: #008300;
  font-family: "AutoZone";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  width: 22%;
  float: left;
}

.pickup {
  @extend .deliver;
  color: #d52b1e;
}

.ship {
  @extend .deliver;
  color: #6c6f70;
}

.vdp {
  @extend .deliver;
  color: #5786a9;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark-grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-grey;
}

#needBarcode {
  margin: 0px 0px 0px 10px;
  min-width: 14px;
}

.transfer {
  color: red;
  font-family: "AutoZone";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  width: 22%;
  float: left;
}

.displayNone {
  display: none;
}

.customerNameLabel {
  margin: 0px 5px 0px;
}
