.label {
  text-transform: uppercase;
  width: 173px;
  height: 14px;
  font-family: "AutoZone Medium";
  font-size: 24px;
  font-weight: 500;
  font-stretch: condensed;
}

.number {
  font-size: 18px;
  font-weight: 500;
  font-family: Arial;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
}

.qtyValue {
  font-family: "AutoZone Medium";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
}

.subLabel {
  font-family: "AutoZone Medium";
  font-size: 16px;
  font-stretch: condensed;
}

.normalText {
  font-family: Arial;
  font-size: 16px;
}
