#app {
  @media all and (min-width: 480px) {
    margin-right: 0px;
    .appBarS {
      background-color: $light-grey;
      color: $black;
      border-bottom: 2px solid #c4c4c4 !important;
      box-shadow: none !important;
      min-height: 0px !important;
      padding-left: 12px;
      div {
        button:focus {
          outline: none;
        }
        .editStoreId {
          width: 22px;
          height: 11px;
          font-family: "Autozone Medium";
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: condensed;
          line-height: normal;
          letter-spacing: normal;
          color: #f26100;
          cursor: pointer;
          margin-right: 4px;
        }
      }
    }
    .storeWrapper {
      width: 100%;
      text-align: right;
      float: right;
      margin-right: 12px;

      .storeNumber {
        font-family: "AutoZone Heavy";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $black;
      }
    }
  }

  @media all and (max-width: 480px) {
    margin-right: 0px;
    .appBarS {
      background-color: $light-grey;
      color: $black;
      border-bottom: 2px solid #c4c4c4 !important;
      box-shadow: none !important;
      min-height: 0px !important;
      div {
        button:focus {
          outline: none;
        }
        .editStoreId {
          width: 22px;
          height: 11px;
          font-family: "Autozone Medium";
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: condensed;
          line-height: normal;
          letter-spacing: normal;
          color: #f26100;
          cursor: pointer;
          margin-right: 4px;
        }
      }
    }

    .storeWrapper {
      width: 100%;
      text-align: right;
      float: right;
      margin-right: 16px;

      .storeNumber {
        font-family: "AutoZone Heavy";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $black;
      }
    }
  }

  .loggedSection {
    font-size: 12px;
    color: $black;
    font-family: Arial;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

#submit button {
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
}

/*
  ##Device = Desktops
  ##Screen = B/w 1511px to 1510px
*/

@media (min-width: 1581px) {
  .drawerWrapper > div:nth-child(1) {
    background: none !important;
  }
}

/*
  ##Device = Desktops
  ##Screen = B/w 1281px to 1510px
*/

@media (min-width: 1281px) and (max-width: 1580px) {
  .drawerWrapper > div:nth-child(1) {
    background: none !important;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .drawerWrapper > div:nth-child(2) {
    left: 0px !important;
  }
  .drawerWrapper > div:nth-child(1) {
    background: none !important;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .drawerWrapper > div:nth-child(2) {
    left: 0px !important;
  }
  .drawerWrapper > div:nth-child(1) {
    background: none !important;
  }
}

.dialog-content {
  padding: 20px;
  border-bottom: 1px solid #efefef;
}

.dialog-btn {
  .btn-primary {
    background: #f37f00;
    background: none;
  }
  float: right;
  margin: 8px;
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .drawerWrapper > div:nth-child(2) {
    left: 0px !important;
  }
  .drawerWrapper > div:nth-child(1) {
    background: none !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .drawerWrapper > div:nth-child(2) {
    left: 0px !important;
  }
  .drawerWrapper > div:nth-child(1) {
    background: none !important;
  }
}

.drawerWrapper {
  color: $white !important;
  ul {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  div:nth-child(2) {
    background-color: #3a3c3d;
    color: $white !important;
  }
  span {
    color: $white !important;
  }
  .mainMenuListItem {
    background-color: $black !important;
    color: #e9e9e9 !important;
    font-family: Arial;
    height: 98px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px solid $white !important;
    .menuWrapper {
      width: 100%;
    }
    #closeBtn {
      position: absolute;
      top: 8px;
      right: 12px;
      width: 15px;
      height: 15px;
    }
    div {
      background-color: $black !important;
    }
    .loginName {
      font-family: Arial;
      font-size: 16px;
      font-weight: bold;
      color: #e9e9e9;
    }
    .loginTime {
      font-family: Arial;
      font-weight: normal;
      font-size: 13px;
      color: #e9e9e9;
      padding-top: 3px;
    }
  }
  .menuListItem {
    left: 0px !important;
    padding: 0px 0px;
  }
  .versionNumber {
    color: #979797;
    font-size: 13px;
  }
}

#menuSubList {
  [class^="MuiListItemText-root-"],
  [class*=" MuiListItemText-root-"] {
    padding: 5px 0px;
  }
  font-family: Arial;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  div {
    padding: 12px 16px !important;
    border-bottom: 0.5px solid #979797 !important;
    left: 0px !important;
    cursor: pointer;
  }
  div {
    span {
      font-family: Arial;
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.appVersionUpdate {
  flex-grow: 1;
  font-size: 12px;
  margin: 0;
  background-color: $light-orange;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  border: 2px solid $primary-orange;
}

.appVersionUpdateButton {
  border: none;
  margin-left: 5px;
  // TODO: Sorry about adding more !importants. Would need to refactor quite a
  // bit of styles, because of mixed specificity.
  margin-right: 0 !important;
  padding: 0 !important;
  font-weight: bold;
  background: none;
}

// Note: we use id here, because Button component handles custom classes badly
#refreshAppButton button {
  font-size: 14px;
  text-transform: uppercase;
}
