.loading-indicator-container {
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation-name: rotate;
  animation-timing-function: ease;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  span {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #f7f7f5;
    margin-left: 3px;
    margin-right: 3px;
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  span:nth-child(2) {
    animation-delay: 200ms;
  }

  span:nth-child(3) {
    animation-delay: 400ms;
  }

  span:nth-child(4) {
    animation-delay: 600ms;
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateX(0);
  }
}

@keyframes bounce {
  50% {
    transform: scale(0.6);
  }
}
