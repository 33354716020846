#itemScanningRequiredDialog {
  .itemScanTitle {
    display: flex;
    flex-direction: row;
    background-color: #9b9b9b;
    color: $black;
    font-family: "AutoZone Heavy";
    padding: 10px 16px 10px 0px;
    text-transform: uppercase;
    .itemScanText {
      justify-content: left;
      flex-grow: 1;
      font-family: "AutoZone Medium";
      padding-left: 10px;
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: condensed;
    }
    .closeBtn {
      color: $black;
      margin: 2px;
      font-size: 12px;
      font-weight: bold;
    }
    .closeIcon {
      width: 0.75em;
      font-weight: bold;
      margin-top: -3px;
      cursor: pointer;
    }
  }
  .itemScanningBodyText {
    padding: 6px 5px 10px 11px;
    margin: 10px 10px 10px 0px;
    color: $black;
    font-size: 14px;
    font-family: Arial;
  }
  .itemScanActionsWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px !important;
    .cancelButtonItemScanModal {
      button {
        color: $black !important;
        border: 1px solid $black;
        background-color: $white;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 6px 23px;
      }
    }
    .proceedButtonItemScanModal {
      button {
        color: $white !important;
        border: 1px solid $primary-orange;
        background-color: $primary-orange;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0;
        padding: 6px 18px;
      }
    }
    button {
      text-transform: uppercase;
    }
  }
}
