@media (max-width: 450px) and (min-width: 360px) {
  #skipValidation {
    .controlSectionFooter {
      width: 58% !important;
    }
  }
}
.clrBoth {
  clear: both;
}
.sml {
  width: 47%;
  text-align: left;
  padding-left: 4px;
}
.btn-alignm {
  display: inline-block;
  position: relative;
  top: -5px;
}
.wid401 {
  width: 48% !important;
}
.wid314 {
  width: 45.7% !important;
}
.wid314 + .seqLabel + .btn-alignm {
  margin-left: 0px;
}
.tx-cntr {
  text-align: center !important;
}
.ctrlSectionItem {
  .seqLabel {
    text-align: right;
  }
  width: 48%;
  padding-top: 22px;
  padding-bottom: 4px;
  float: left;
}

.textAlignRight {
  text-align: right;
}

.planoWrapperStyle {
  width: 80%;
  padding-top: 20px;
}
.itemFoundButton .btn {
  width: 132px;
}
.itemFound {
  padding-top: 0px;
  margin-top: -24px;
}

.pt-24 {
  padding-top: 24px;
}
@media all and (max-width: 480px) {
  .itemErrorWrapper {
    display: flex;
    float: right;
    width: 100%;
    justify-content: center;
    text-align: center;
    margin: 7px;
  }
  #itemFound {
    .itemErrorWrapper {
      display: flex;
    float: right;
    width: 100%;
    justify-content: flex-end;
    text-align: right;
    margin: 10px 0px;
    }
  }
  .labelPartIdWrapper {
    line-height: 24px;
  }

  .itemDesc {
    margin-bottom: 0px !important;
  }

  .ctrlSectionItem {
    padding-bottom: 0px !important;
  }

  .seqLabel.sml {
    text-align: right !important;
  }
  #app #ordering .mar-lt-rt4 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .planoWrapperStyle {
    width: 100%;
    padding-top: 0px;
  }

  .planoGramLabel {
    width: 90% !important;
  }
  .seqLabel {
    width: 10%;
    text-align: right;
  }
  .ctrlSectionItem {
    width: 100%;
    padding-top: 0px;
  }
  #skipValidation {
    .errMsg #errorMessageWrapper {
      padding-bottom: 7px;
    }
    .itemDesc {
      margin-top: 0px !important;
    }

    .labelPartIdWrapper {
      padding-bottom: 0px !important;
    }
    .actionAlignedItems {
      .itemFound {
        padding-top: 40px;
        width: 100%;
        padding-left: 0px;
        button {
          width: 100%;
        }
      }
      .controlSectionFooterItemFound {
        position: static;
        top: 4px;
        width: 100%;
        .ctrlSectionItem {
          width: 0%;
        }
        .seqLabel {
          vertical-align: middle;
          font-family: Arial;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #231f20;
          text-align: right;
          display: flex;
          justify-content: center;
          min-width: 0px;
        }
        .itemFound {
          width: 100% !important;
          padding-top: 45px;
          padding-left: 0px !important;
          button {
            width: 100% !important;
          }
        }
        .planogramWrapper {
          display: flex;
          width: 96%;
          .planoGramLabel {
            width: 65%;
            display: flex;
            justify-content: flex-start;
            min-width: 0px;
          }
          .labelHeader,
          .labelHeaderSeq {
            font-family: Arial;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            padding-top: 14px;
            width: 50%;
            display: flex;
            justify-content: flex-start;
            min-width: 0px;
          }
          .labelHeaderSeq {
            justify-content: flex-end;
          }
        }
      }

      .controlSectionFooter {
        position: static;
        top: 4px;
        width: 58%;
        .seqLabel {
          vertical-align: middle;
          font-family: Arial;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #231f20;
          width: 10% !important;
          display: flex;
          justify-content: flex-end;
          min-width: 0px;
        }
        .planogramWrapper {
          display: flex;
          width: 100%;
          .planoGramLabel {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            min-width: 0px;
          }
          .labelHeader,
          .labelHeaderSeq {
            font-family: Arial;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            padding-top: 14px;
            width: 50%;
            display: flex;
            justify-content: flex-start;
            min-width: 0px;
          }
          .labelHeaderSeq {
            justify-content: flex-end;
          }
        }
      }
    }
    .selectedHeadingSection {
      background-color: $active-bg-color;
      color: $white !important;

      .alignedItems {
        padding: 8px 16px;

        .labelPartId {
          padding-left: 0px;
          padding-right: 4px;
          vertical-align: baseline;
        }

        .noOfItems {
          font-family: "AutoZone Medium";
          font-size: 18px;
          padding-top: 3px;
          font-weight: normal;
          font-style: normal;
          font-stretch: condensed;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          float: right;
        }

        .quantityPickWrapper {
          display: flex;
          flex-direction: row;
          .qtyRequested {
            justify-content: left;
            flex-grow: 1;
            font-family: "AutoZone Medium";
            font-size: 16px;
            font-style: normal;
            font-stretch: condensed;
            color: $white;
          }
          .qtyPicked {
            justify-content: left;
            flex-grow: 1;
            font-family: "AutoZone Medium";
            font-size: 16px;
            font-style: normal;
            font-stretch: condensed;
            color: $white;
            text-align: center;
          }
          .qtySkipped {
            justify-content: right;
            flex-grow: 1;
            font-family: "AutoZone Medium";
            font-size: 16px;
            font-style: normal;
            font-stretch: condensed;
            color: $white;
            text-align: right;
            float: right;
          }
        }
      }
    }

    .unSelectedHeadingSection {
      background-color: $inactive-bg-color;
      border-bottom: 0.5px solid #7f7f7f;
      .alignedItems {
        .quantityPickWrapper {
          display: flex;
          flex-direction: row;
          .qtyRequested {
            justify-content: left;
            flex-grow: 1;
            font-family: "AutoZone Medium";
            font-size: 16px;
            font-style: normal;
            font-stretch: condensed;
            color: $black;
          }
          .qtyPicked {
            justify-content: left;
            flex-grow: 1;
            font-family: "AutoZone Medium";
            font-size: 16px;
            font-style: normal;
            font-stretch: condensed;
            color: $black;
            text-align: center;
          }
          .qtySkipped {
            justify-content: right;
            flex-grow: 1;
            font-family: "AutoZone Medium";
            font-size: 16px;
            font-style: normal;
            font-stretch: condensed;
            color: $black;
            text-align: right;
            float: right;
          }
        }
      }
    }
  }
}

@media all and (min-width: 481px) {
  .itemFoundErrorWrapper {
    width: 100% !important;
    padding-bottom: 7px;
  }

  .itemErrorWrapper {
    display: flex;
    justify-content: flex-end;
    width: 43%;
  }
  .ctrlSectionItem {
    float: left;
    position: relative;
    margin-top: -51px;
  }

  .mtp16 {
    margin-top: 28px;
  }
  .MuiDivider-root-100 {
    margin-top: 12px !important;
  }
  .unSelectedHeadingSection {
    background-color: $inactive-bg-color;
    border-bottom: 0.5px solid #7f7f7f;
    .alignedItems {
      .quantityPickWrapper {
        display: flex;
        flex-direction: row;
        .qtyRequested {
          justify-content: left;
          flex-grow: 1;
          font-family: "AutoZone Medium";
          font-size: 16px;
          font-style: normal;
          font-stretch: condensed;
          color: $black;
        }
        .qtyPicked {
          justify-content: left;
          flex-grow: 1;
          font-family: "AutoZone Medium";
          font-size: 16px;
          font-style: normal;
          font-stretch: condensed;
          color: $black;
          text-align: center;
        }
        .qtySkipped {
          justify-content: right;
          flex-grow: 1;
          font-family: "AutoZone Medium";
          font-size: 16px;
          font-style: normal;
          font-stretch: condensed;
          color: $black;
          text-align: right;
          float: right;
        }
        .ctrlSectionItem {
          padding-bottom: 5px;
          .seqLabel {
            width: 37%;
          }
        }
      }
    }
  }
}

.fldset {
  margin: 0px 0;
  position: relative;
  display: inline-block;
  padding-top: 14px;
  width: 180px;
  margin-right: 10px;
}

.fldset legend {
  padding: 10px;
  pointer-events: none;
  position: absolute;
  left: 0px;
  top: 4px;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
  font-size: 14px;
  margin-left: 2px !important;
}
.fldset input {
  padding-left: 12px !important;
  width: 100%;
  text-align: left;
  margin-right: 0px !important;
}
.fldset.wid115 {
  width: 115px !important;
}

.fldset input:focus + legend,
.fldset input:not(:placeholder-shown) + legend {
  padding: 10px 10px 8px 5px;
  font-size: 16px;
  top: -7px;
  line-height: 10px;
  left: 0px !important;
  opacity: 1;
  transform: scale(0.75) translateY(-30%) translateX(-10px);
  background-color: #fff;
}
.fldset input:focus + legend.qty,
.fldset input:not(:placeholder-shown) + legend.qty {
  left: 25px !important;
}
/* For IE Browsers*/

.fldset legend.qty {
  left: 16px !important;
}

.fldset input:focus + legend,
.fldset input:not(:-ms-input-placeholder) + legend {
  opacity: 1;
  transform: scale(0.75) translateY(-30%) translateX(-10px);
  background-color: #fff;
}
