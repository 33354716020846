#noInternet {
  h4 {
    color: $red;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 16px;
  }
  .wrapper {
    width: 300px;
    height: 120px;
    border: 1px solid $placeholder-color;
    text-align: center;
    padding: 24px 19px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .wrap-center {
    margin: 0 auto;
    margin-bottom: 24px;
  }

  .wrap-center:first-child {
    margin-top: 47px;
    height: 140px;
  }

  img {
    align-self: center;
  }
}

#linkTryAgain {
  margin-top: 20px;
}

.noInternetIcon {
  margin-bottom: 4px;
}

@media all and (min-width: 480px) {
  .wrapper {
    margin-top: 38px;
  }
}

@media all and (max-width: 480px) {
  #noInternet {
    text-align: center;
  }
}
