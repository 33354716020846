#noStoreDialog {
  .noTitle {
    display: flex;
    flex-direction: row;
    background-color: #9b9b9b;
    color: $black;
    padding: 10px 0px;
    margin-bottom: 16px;
    text-transform: uppercase;
    .noText {
      justify-content: left;
      flex-grow: 1;
      font-family: "AutoZone Heavy";
      padding-left: 16px;
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: condensed;
    }
    .closeBtn {
      color: $black;
      margin: 2px;
      font-size: 12px;
      font-weight: bold;
    }
    .closeIcon {
      width: 0.75em;
      font-weight: bold;
      margin-top: -3px;
      cursor: pointer;
    }
  }
  .noBody {
    padding: 12px 16px;
    margin-bottom: 24px;
    color: $black;
    background-color: #e9e9e9;
    font-size: 12px;
    font-weight: bold;
    #bodyLine1 {
      display: flex;
      flex-direction: row;
      #bodyLabel {
        padding: 5px 0px;
        font-family: "AutoZone";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: condensed;
      }
      #bodyValue {
        color: green;
        font-size: 18px;
      }
    }
    #bodyLine2 {
      display: flex;
      flex-direction: row;
      #bodyLabel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 173px;
        font-family: "AutoZone";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: condensed;
      }
      #bodyValue {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-family: "AutoZone";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: condensed;
      }
    }
    #bodyLine3 {
      display: flex;
      flex-direction: row;
      #bodyLabel {
        text-transform: uppercase;
        width: 173px;
        height: 14px;
        font-family: "AutoZone Medium";
        font-size: 18px;
        font-weight: 500;
        font-stretch: condensed;
      }
      #bodyValue {
        font-family: "AutoZone Medium";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;
      }
    }
  }
  .noActionsWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px !important;
    .noFulfilManually {
      button {
        color: $primary-orange !important;
        border: 1px solid $primary-orange;
        background-color: $white;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 8px 22px;
      }
    }
    .noForward {
      button {
        color: $white !important;
        border: 1px solid $primary-orange;
        background-color: $primary-orange;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        padding: 8px 22px;
      }
    }
    button {
      text-transform: uppercase;
    }
  }
  #noStoreError {
    margin-bottom: 60px;
    margin-top: -20px;
  }
}
