#errorMessageWrapper {
  display: flex;
  float: right;
  .alertIcon {
    width: 16px;
    height: 16px;
  }
  #errorMessage {
    height: 5px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #d52b1e;
    vertical-align: middle;
    margin: 7px 0px 7px 5px;
  }
}
