#customerStoreDialog {
  .scrollWrapper {
    overflow-y: auto;
    max-height: 270px;
  }
  .pinNameSection {
    display: flex;
    flex-direction: row;
    background-color: #4278a0;
    color: white;
    padding: 4px 16px 4px 13px;
    .nameWrapper {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: left;
      .nameKey {
        font-size: 14px;
        margin: 5px;
      }
      .nameValue {
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        letter-spacing: normal;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .pinWrapper {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .pinKey {
        font-size: 14px;
        margin: 5px;
      }
      .pinValue {
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        letter-spacing: normal;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
  .customerTitle {
    display: flex;
    flex-direction: row;
    background-color: #9b9b9b;
    color: $black;
    padding: 10.5px 0px;
    text-transform: uppercase;
    .customerText {
      justify-content: left;
      flex-grow: 1;
      font-family: "AutoZone Medium";
      padding-left: 16px;
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: condensed;
    }
    .closeBtn {
      color: $black;
      margin: 2px 14px 2px 0px;
      font-size: 12px;
      font-weight: bold;
    }
    .closeIcon {
      width: 0.75em;
      font-weight: bold;
      margin-top: -3px;
      cursor: pointer;
    }
  }
  .customerBody {
    margin-bottom: 24px;
    padding-top: 16px;
    padding-left: 16px;
    color: $black;
    font-size: 12px;
    font-family: Arial;
    font-size: 14px;
    #bodyLine1 {
      display: flex;
      flex-direction: row;
      #bodyLabel {
        word-break: break-all;
      }
      #bodyValue {
        color: green;
        font-size: 18px;
      }
    }
  }
  .customerActionsWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px !important;
    .btnCancel {
      button {
        color: $black !important;
        border: 1px solid $black;
        background-color: $white;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 6px 23px;
      }
    }
    .btnOk {
      button {
        color: $white !important;
        border: 1px solid $primary-orange;
        background-color: $primary-orange;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0;
        padding: 6px 18px;
      }
    }
    button {
      text-transform: uppercase;
    }
  }
}

#customerItemError {
  margin-bottom: 60px;
  margin-top: -20px;
}
