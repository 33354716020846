.selectedControlSection {
  max-width: 651px;
  width: 100%;
}
.planoGramLabel {
  display: inline-block;
  width: 45.7%;
  padding-bottom: 16px;
  margin-right: 0px !important;
  line-height: 16px;
}

.pb-16 {
  padding-top: 16px !important;
}

.seqLabel {
  display: inline-block;
  width: 47%;
  margin-right: 0px !important;
}
.actionAlignedItems .selectedControlSection.skipitm {
  max-width: 850px;
}

.fldset {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: 16px;
  float: left;
  height: 49px;
}
.fldset legend {
  margin-left: 7px;
  color: #231f20;
  margin-right: 10px;
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
  display: inline-block;
  width: auto;
  margin-bottom: 3px;
}
.fldset input,
.fldset #activeSkipPartNumber {
  border: 0px solid transparent;
  outline: none;
  box-shadow: none;
  font-size: 16px !important;
  padding-top: 0;
  padding-bottom: 0px;
  padding-left: 12px;
  height: 20px;
  font-size: 14px;
}
.skipAction,
.MuiDivider-root-85 {
  clear: both;
}
.dialogMainWrapper .skipTitle {
  height: 45px;
  padding-top: 12px;
}

.activeOrdersWrapper {
  .labelPartIdValue {
    @extend .label;
  }
  .skuValue {
    @extend .qtyValue;
  }
  .labelQuantityPickValue {
    @extend .qtyValue;
    padding: "0.275rem 0.10rem";
    margin-right: "5px";
    margin-left: "16px";
  }
  .labelIdValue {
    font-family: "AutoZone Medium";
    font-stretch: condensed;
    letter-spacing: normal;
    font-size: 20px;
    font-weight: 500;
  }

  .seqValue {
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #231f20;
  }

  .noOfItems {
    font-family: "AutoZone Medium";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    float: right;
  }
  .labelPartId {
    @extend .subLabel;
    vertical-align: baseline;
  }

  .labelQuantityPick,
  .sku {
    @extend .subLabel;
    vertical-align: top;
  }
  .itemDesc {
    @extend .normalText;
    margin-top: 8px;
    width: 46%;
  }
  .controls {
    padding: 0px 10px;
  }

  .actionItems {
    display: flex;
    flex-direction: row;
  }
  .completeButtonWrapper {
    width: 46%;
    text-align: left;
  }

  .selectedHeadingSection {
    background-color: $active-bg-color;
    color: $white !important;
  }
  .unSelectedHeadingSection {
    background-color: $inactive-bg-color;
    color: $black !important;
    border-bottom: 0.5px solid #7f7f7f;
  }

  .alignedItems {
    padding: 7px 16px 12px;
  }
  .actionAlignedItems {
    padding: 16px 12px 0px 12px;
    min-height: 117px;
    overflow: hidden;
  }
  @media all and (min-width: 480px) {
    .actionAlignedItems {
      width: 100%;
    }

    [class*="theme--body--"],
    [class^=" theme--body--"] {
      padding: 16px !important;
    }
    .skipTitle {
      display: flex;
      flex-direction: row;
      background-color: #9b9b9b;
      color: $black;
      margin-bottom: 0px;
      text-transform: uppercase;
      .skipText {
        width: 86%;
        height: 16px;
        font-family: "AutoZone Heavy";
        font-size: 16px;
        font-weight: 900;
        font-style: normal;
        font-stretch: condensed;
        letter-spacing: normal;
        color: $black;
        margin-left: 16px;
      }
      .closeBtn {
        height: 14px;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: $black;
        margin-right: 16px;
        vertical-align: middle;
        margin-top: 2px;
      }
      .closeIcon {
        width: 0.75em;
        font-weight: bold;
        margin-top: -3px;
        cursor: pointer;
      }
      .closeI {
        margin-right: 16px;
      }
    }
    .quantityPickWrapperBottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .qtyRequested {
        justify-content: left;
        flex-grow: 1;
        font-family: "AutoZone Medium";
        font-size: 16px;
        font-style: normal;
        font-stretch: condensed;
        color: $black;
      }
      .qtyPicked {
        justify-content: left;
        flex-grow: 1;
        font-family: "AutoZone Medium";
        font-size: 16px;
        font-style: normal;
        font-stretch: condensed;
        color: $black;
        text-align: center;
      }
      .qtySkipped {
        justify-content: right;
        flex-grow: 1;
        font-family: "AutoZone Medium";

        font-size: 16px;
        font-style: normal;
        font-stretch: condensed;
        color: $black;
        text-align: right;
        float: right;
      }
    }
    .quantityPickWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .qtyRequested {
        justify-content: left;
        flex-grow: 1;
        font-family: "AutoZone Medium";
        font-size: 16px;
        font-style: normal;
        font-stretch: condensed;
        color: $white;
      }
      .qtyPicked {
        justify-content: left;
        flex-grow: 1;
        font-family: "AutoZone Medium";
        font-size: 16px;
        font-style: normal;
        font-stretch: condensed;
        color: $white;
        text-align: center;
      }
      .qtySkipped {
        justify-content: right;
        flex-grow: 1;
        font-family: "AutoZone Medium";
        font-size: 16px;
        font-style: normal;
        font-stretch: condensed;
        color: $white;
        text-align: right;
        float: right;
      }
    }
    .skipBar {
      display: flex;
      flex-direction: row;
      background-color: #4278a0;
      color: $black;
      padding: 5px 0px 5px 5px;
      margin-bottom: 16px;
      .skipValLabel {
        font-family: "AutoZone Medium";
        font-size: 16px;
        font-stretch: condensed;
        color: $white;
      }
      .skipValLabelValue {
        font-family: "AutoZone Medium";
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
        margin: 5px;
      }
      .requestText {
        justify-content: left;
        flex-grow: 1;
        color: $white;
        margin-left: 16px;
      }
      .pickText {
        justify-content: left;
        flex-grow: 1;
        text-align: center;
      }
      .skippedText {
        justify-content: right;
        flex-grow: 1;
        text-align: right;
        margin-right: 11px;
      }
    }

    .skipBody {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;
      padding-bottom: 22px !important;
      .formControl {
        margin: 0px 10px;
      }
      .skipPartLabel {
        margin-top: 7px;
        color: $black;
        font-size: 14px;
      }
      input[type="number"] {
        margin: 0;
        display: block;
        width: 20%;
        height: calc(2.25rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $black;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #3a3c3d;
      }
      .skipQtySection {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: 48px;
      }
    }
    .skipAction {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 16px !important;
      .secondaryButton {
        button {
          color: $primary-orange !important;
          border: 1px solid $primary-orange;
          background-color: $white;
          font-size: 14px;
          font-weight: 900;
          font-family: "AutoZone Medium";
          font-stretch: condensed;
          border-radius: 0;
          margin-right: 16px;
          padding: 7px 18px;
        }
      }
      .noSecondaryButton {
        button {
          color: $black !important;
          border: 1px solid $black;
          background-color: $white;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Medium";
          font-stretch: condensed;
          border-radius: 0;
          margin-right: 16px;
          padding: 8px 40px;
        }
      }
      .submitButtonModal {
        button {
          color: $white !important;
          border: 1px solid $primary-orange;
          background-color: $primary-orange;
          font-size: 14px;
          font-weight: 900;
          font-family: "AutoZone Medium";
          font-stretch: condensed;
          border-radius: 0;
          margin-right: 0px;
          padding: 8px 27px;
        }
      }
      button {
        text-transform: uppercase;
      }
    }

    .submitButton {
      button {
        border: 1px solid $primary-orange !important;
        background-color: $primary-orange !important;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0px;
        padding: 4px 39px;
      }
    }
    .skipAllButton {
      button {
        border: 1px solid $primary-orange !important;
        background-color: $white;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0px;
        padding: 4px 39px;
      }
    }

    [class^="theme--body--"],
    [class*=" theme--body--"] {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  .quantityPickWrapper {
    display: flex;
    justify-content: space-between;
  }
  .labelPartId {
    padding-left: 0px;
    padding-right: 4px;
    vertical-align: baseline;
  }
  .labelQuantityPick {
    padding-right: 5px;
  }
  #linkSkip {
    float: right;
  }
  .planoGramLabel {
    margin-right: 16px;
  }
  .skipPlanoGramLabel {
    margin-right: 16px;
  }
  .seqLabel {
    vertical-align: middle;
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #231f20;
  }
  .labelHeader {
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    padding-top: 14px;
    min-width: 458px;
  }
  .labelHeaderSkip {
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    padding-top: 14px;
    min-width: 458px;
  }

  .labelHeaderSeq {
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    padding-top: 14px;
  }
  .controlSectionFooter:first-child .pogLabel {
    padding-right: 5px;
    font-weight: bold;
  }
  .sat2satInfo {
    display: flex;
    justify-content: space-between;
  }
  .secondaryButton {
    button {
      color: $primary-orange;
      border: 1px solid $primary-orange;
      background-color: $white;
      font-family: "AutoZone Heavy";
      text-transform: uppercase;
      border-radius: 0;
      padding: 7px 18px;
      margin-bottom: 8px;
      font-stretch: normal;
    }
  }

  .confirmOk {
    button {
      border: 1px solid $primary-orange !important;
      background-color: $primary-orange !important;
      font-family: "AutoZone Heavy";
      text-transform: uppercase;
      border-radius: 0;
      font-size: 14px;
      font-weight: 900;
      margin-right: 0px;
      padding: 3px 24px;
      margin-bottom: 0px;
      font-stretch: normal;
      box-shadow: none;
    }
  }

  .confirmSkip {
    button {
      border: 1px solid $primary-orange !important;
      background-color: $primary-orange !important;
      font-family: "AutoZone Heavy";
      text-transform: uppercase;
      border-radius: 0;
      font-size: 16px;
      font-weight: 900;
      margin-right: 0px;
      padding: 7px 16px 7px 16px;
      margin-bottom: 22px;
      font-stretch: normal;
    }
  }
  .itemFoundButton {
    button {
      color: $primary-orange;
      border: 1px solid $primary-orange;
      background-color: $white;
      font-family: "AutoZone Heavy";
      font-stretch: "condensed";
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;
      border-radius: 0;
      padding: 7px 22px 7px 22px;
      margin-bottom: 0px;
      font-stretch: normal;
    }
  }
  .itemFound {
    padding-left: 10px;
  }
  .controlSectionFooter {
    position: static;
    top: 4px;
  }
}

@media all and (min-width: 362px) and (max-width: 450px) {
  .controlSectionFooter {
    width: 100% !important;
  }
}

.activeOrdersWrapper .actionAlignedItems .din {
  text-align: left;
  display: inline-block;
  width: 35px;
}
@media all and (max-width: 380px) {
  #completePick .invoicesPrinted,
  #completePick .invoicesPrintedNoPadding {
    font-size: 16px;
    word-wrap: break-word;
    padding: 20px;
  }
}
@media (max-width: 480px) {
  #completeItemErrorWrapper {
    justify-content: center;
    display: flex;
  }
  .errMsg #errorMessageWrapper {
    padding-bottom: 7px;
  }

  .invoicesPrinted,
  .invoicesPrintedNoPadding {
    font-size: 16px;
  }

  .skipBar .skipValLabel {
    font-size: 14px;
  }

  [class^="theme--dialog--"],
  [class*=" theme--dialog--"] {
    width: 93% !important;
  }

  .alignedItems {
    padding: 10px 16px 9px 16px !important;
  }
  .activeOrdersWrapper .actionAlignedItems .din {
    text-align: right;
  }

  .activeOrdersWrapper .actionAlignedItems {
    padding: 16px 16px !important;
  }
  .itemDesc {
    margin-top: 10px !important;
    float: left;
    width: 80% !important;
  }
  .itemDesc + .itemQtyWrapper {
    width: auto !important;
  }
  .itemDesc + .itemQtyWrapper input {
    margin-right: 0px !important;
  }
  .itemQtyWrapper {
    float: right;
    width: 9%;
    margin-right: 5px;
  }
  #activeListSection {
    .itemQtyWrapper {
      margin-right: 0px;
    }
  }
  #activeListSection {
    .itemQtyWrapper {
      margin-right: 0px;
    }
  }

  .completeButtonWrapper {
    margin-top: 0px;
    width: 100% !important;
    text-align: center;
    button {
      width: 100%;
    }
  }

  .actionItems {
    display: block !important;
  }

  #validationModal {
    .validationTitle {
      display: flex;
      flex-direction: row;
      background-color: #9b9b9b;
      color: $black;
      padding: 11px 16px 11px 11px;
      margin-bottom: 16px;
      text-transform: uppercase;
      .validationText {
        justify-content: left;
        flex-grow: 1;
        font-family: "AutoZone Heavy";
        padding-left: 5px;
        font-size: 16px;
        font-weight: 900;
        font-style: normal;
        font-stretch: condensed;
      }
      .closeBtn {
        color: $black;
        margin: 2px;
        font-size: 12px;
        font-weight: bold;
        .closeImg {
          width: 0.75em;
          font-weight: bold;
          margin-top: -3px;
          cursor: pointer;
        }
      }
    }
    .validationBar {
      color: $black;
      font-size: 12px;
      font-weight: bold;
      .validationText {
        .validationLabel {
          margin-left: 16px;
          font-family: Arial;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #231f20;
        }
      }
    }
    .validationBody {
      .controls {
        display: flex;
        flex-direction: row;
      }
      display: flex;
      flex-direction: column;
      .submittingError {
        margin-top: -10px;
        margin-bottom: 10px;
      }
      #validationBarcodeValue {
        width: 220px;
        height: 38px;
        border: solid 1px #c4c4c4;
        background-color: #ffffff;
      }
      .validationQtySection {
        #validationQtyTextBox {
          width: 48px;
          height: 38px;
          border: solid 1px #c4c4c4;
          background-color: #ffffff;
          margin: 16px;
          padding: 0px;
          text-align: center;
        }
      }
    }
    .validationAction {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 16px !important;
      .cancelButtonItemScanModal {
        button {
          color: $black !important;
          border: 1px solid $black;
          background-color: $white;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Heavy";
          font-stretch: condensed;
          border-radius: 0;
          margin-right: 16px;
          padding: 6px 24px;
        }
      }
      .validationButtonItemScanModal {
        button {
          color: $white !important;
          border: 1px solid $primary-orange;
          background-color: $primary-orange;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Heavy";
          font-stretch: condensed;
          border-radius: 0;
          padding: 6px 23px;
        }
      }
      button {
        text-transform: uppercase;
      }
    }
  }

  #validationScanCodeMessage {
    width: auto !important;
    max-width: "100%" !important;
    height: 38px;
    border: solid 1px #d52b1e;
    background-color: #fff2f0;
    display: flex;
    flex-direction: row;
    padding: 3px;
    margin-bottom: 10px;
    .validationIcon {
      width: 16px;
      height: 16px;
      margin-right: 0px;
      padding: 2px 3px 3px 3px;
    }
    .validationMessage {
      text-align: center;
      vertical-align: middle;
      width: auto;
      height: 18px;
      font-family: Arial;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #231f20;
      margin: 6px 0px;
      padding: 0px 0px 0px 6px !important;
    }
  }

  .controlSectionFooter {
    position: static;
    top: 4px;
    width: 100%;
    .seqLabel {
      vertical-align: middle;
      font-family: Arial;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #231f20;
      width: 11%;
      display: flex;
      justify-content: flex-end;
      min-width: 0px;
      text-align: left !important;
    }
    .planogramWrapper {
      display: flex;
      width: 100%;
      padding-top: 0px;
      .planoGramLabel {
        width: 100%; // 80
        display: flex;
        justify-content: flex-start;
        min-width: 0px;
      }
      .labelHeader,
      .labelHeaderSeq {
        font-family: Arial;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        padding-top: 14px;
        width: 50%;
        display: flex;
        justify-content: flex-start;
        min-width: 0px;
      }
      .labelHeaderSeq {
        justify-content: flex-end;
      }
    }
  }
  .skipQtySection {
    margin-left: 0px !important;
  }

  .skipBody > div:nth-child(2) {
    width: 32% !important;
    margin-right: 20px;
  }
  .planogramWrapper {
    .planoGramLabel {
      min-width: 240px;
      width: 90%;
    }
  }
  .skipPlanoGramWrapper {
    .skipPlanoGramLabel {
      min-width: 100px;
    }
  }

  .skipTitle {
    display: flex;
    flex-direction: row;
    background-color: #9b9b9b;
    color: $black;
    margin-bottom: 0px;
    text-transform: uppercase;

    .skipText {
      width: 86%;
      font-family: "AutoZone Heavy";
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: condensed;
      letter-spacing: normal;
      color: $black;
      margin-left: 16px;
    }

    .closeBtn {
      height: 14px;
      font-family: Arial;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $black;
      margin-right: 10px;
      vertical-align: middle;
      margin-top: 2px;
    }
    .closeIcon {
      width: 0.75em;
      font-weight: bold;
      margin-top: -3px;
      cursor: pointer;
    }
    .closeI {
      margin-right: 16px;
    }
  }

  .noSecondaryButton {
    button {
      color: $black !important;
      border: 1px solid $black;
      background-color: $white;
      font-size: 16px;
      font-weight: 900;
      font-family: "AutoZone Medium";
      font-stretch: condensed;
      line-height: normal;
      letter-spacing: normal;
      color: $white;
    }
    .requestText {
      justify-content: left;
      flex-grow: 1;
      color: $white;
      margin-left: 16px;
    }
    .pickText {
      justify-content: left;
      flex-grow: 1;
      text-align: center;
    }
    .skippedText {
      justify-content: right;
      flex-grow: 1;
      text-align: right;
      margin-right: 11px;
    }
  }

  .skipBody {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    .formControl {
      margin: 0px 10px;
    }
    .skipPartLabel {
      margin-top: 7px;
      color: $black;
      font-size: 14px;
      margin: 5px;
    }
    input[type="number"] {
      margin: 0;
      display: block;
      width: 20%;
      height: calc(2.25rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $black;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #3a3c3d;
    }
    .skipQtySection {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-left: 48px;
    }
  }
  .skipAction {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px !important;
    .secondaryButton {
      button {
        color: $primary-orange !important;
        border: 1px solid $primary-orange;
        background-color: $white;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 3px 29px;
      }
    }
    .noSecondaryButton {
      button {
        color: $black !important;
        border: 1px solid $black;
        background-color: $white;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 8px 40px;
      }
    }
    .submitButtonModal {
      button {
        color: $white !important;
        border: 1px solid $primary-orange;
        background-color: $primary-orange;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 0px;
        padding: 3px 29px;
      }
    }
    button {
      text-transform: uppercase;
      border-radius: 0;
      margin-right: 16px;
      padding: 7px 40px;
    }
  }
}

#activeSkipPartNumber {
  width: 178px;
  margin: 0px;
  border: 1px solid #3a3c3d;
  font-size: 14px;
  background-color: $white;
}

.itemOrderQty {
  width: 38px;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3a3c3d;
  background: none;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #000;
  padding-right: 5px;
  margin-right: 16px;
  outline: none;
  padding: 0px 5px 0px 5px;
  text-align: center;
  padding-top: 1px !important;
}

.scanOrderQty {
  width: 48px;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3a3c3d;
  background: none;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #000;
  padding-right: 5px;
  margin-right: 16px;
  outline: none;
  padding: 0px 5px 0px 5px;
  text-align: center;
}

.skipItemOrderQty {
  width: 38px;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3a3c3d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #3a3c3d;
  padding-right: 5px;
  outline: none;
}
.skipItemOrderQtyBig {
  @extend .skipItemOrderQty;
  @extend .itemOrderQty;
  width: 38px;
  text-align: center;
  padding: 0px !important;
}

#completePick {
  min-height: 500px;
  h4 {
    color: $black;
    text-align: center;
  }
  .wrapper {
    text-align: center;
    padding-bottom: 16px;
    margin-left: -17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .invoicesPrinted,
  .invoicesPrintedNoPadding {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #231f20;
  }
  .invoicesPrinted {
    padding-top: 25px;
  }
  .confirmOk {
    button {
      border: 1px solid $primary-orange !important;
      background-color: $primary-orange !important;
      font-family: "AutoZone Heavy";
      text-transform: uppercase;
      border-radius: 0;
      font-size: 16px;
      font-weight: 900;
      margin-right: 0px;
      padding: 6px 16px;
      font-stretch: normal;
      box-shadow: none;
      margin-top: 24px;
    }
  }
  .confirmSkip {
    button {
      border: 1px solid $primary-orange !important;
      background-color: $primary-orange !important;
      font-family: "AutoZone Heavy";
      text-transform: uppercase;
      border-radius: 0;
      font-size: 16px;
      font-weight: 900;
      margin-right: 0px;
      padding: 8px 16px;
      margin-bottom: 10px;
      font-stretch: normal;
      box-shadow: none;
      margin-top: 25px;
    }
  }
  .itemFoundButton {
    button {
      color: $primary-orange;
      border: 1px solid $primary-orange;
      background-color: $white;
      font-family: "AutoZone Heavy";
      margin-right: 25%;
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;
      border-radius: 0;
      padding: 5px 29px;
      margin-bottom: 8px;
      font-stretch: normal;
      box-shadow: none;
      margin-top: 25px;
    }
  }
}

#btnCompleteItem {
  button {
    margin-bottom: 0px;
    height: 38px;
    font-size: 16px;
    font-weight: 900;
  }
}
.act500 {
  width: 100%;
  margin-right: 0px;
  padding-bottom: 16px;
}

.bgGrey {
  background-color: $dark-grey !important;
}
@media (min-width: 481px) {
  .act500 {
    padding-bottom: 0px;
  }
  #completeItemErrorWrapper {
    display: flex;
    justify-content: flex-end;
    width: 40%;
  }
  // .planogramWrapper:last-child .planoGramLabel { padding-bottom:0px !important; }
  #validationModal {
    .validationTitle {
      display: flex;
      flex-direction: row;
      background-color: #9b9b9b;
      color: $black;
      padding: 11px 16px 11px 11px;
      margin-bottom: 16px;
      text-transform: uppercase;
      .validationText {
        justify-content: left;
        flex-grow: 1;
        font-family: "AutoZone Heavy";
        padding-left: 5px;
        font-size: 16px;
        font-weight: 900;
        font-style: normal;
        font-stretch: condensed;
      }
      .closeBtn {
        color: $black;
        margin: 2px;
        font-size: 12px;
        font-weight: bold;
        .closeImg {
          width: 0.75em;
          font-weight: bold;
          margin-top: -3px;
          cursor: pointer;
        }
      }
    }
    .validationBar {
      color: $black;
      font-size: 12px;
      font-weight: bold;
      .validationText {
        .validationLabel {
          margin-left: 16px;
          font-family: Arial;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #231f20;
        }
      }
    }
    .validationBody {
      .controls {
        display: flex;
        flex-direction: row;
      }
      display: flex;
      flex-direction: column;
      .submittingError {
        margin-top: -10px;
        margin-bottom: 10px;
      }
      #validationBarcodeValue {
        width: 220px;
        height: 38px;
        border: solid 1px #c4c4c4;
        background-color: #ffffff;
      }
      .validationQtySection {
        #validationQtyTextBox {
          width: 48px;
          height: 38px;
          border: solid 1px #c4c4c4;
          background-color: #ffffff;
          margin: 16px;
          padding: 0px;
          text-align: center;
        }
      }
    }
    .validationAction {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 13px !important;
      .cancelButtonItemScanModal {
        button {
          color: $black !important;
          border: 1px solid $black;
          background-color: $white;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Heavy";
          font-stretch: condensed;
          border-radius: 0;
          margin-right: 16px;
          padding: 7px 24px;
        }
      }
      .validationButtonItemScanModal {
        button {
          color: $white !important;
          border: 1px solid $primary-orange;
          background-color: $primary-orange;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Heavy";
          font-stretch: condensed;
          border-radius: 0;
          padding: 7px 22px;
        }
      }
      button {
        text-transform: uppercase;
      }
    }
  }

  #validationScanCodeMessage {
    width: auto;
    max-width: 100% !important;
    height: 38px;
    border: solid 1px #d52b1e;
    background-color: #fff2f0;
    display: flex;
    flex-direction: row;
    margin: 0px 16px;
    position: relative;
    padding: 3px;
    margin-bottom: 10px;
    .validationIcon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      padding: 0px 3px 3px 3px;
    }
    .validationMessage {
      text-align: center;
      vertical-align: middle;
      width: auto;
      height: 18px;
      font-family: Arial;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #231f20;
      margin: 5px;
      padding: 0px 0px 0px 10px;
    }
  }
}
#validationBarcodeValue {
  padding: 10px;
  margin: 16px 8px 18px 16px;
  border-radius: 0px !important;
}

#validationBarcodeValue::placeholder {
  color: $placeholder-color;
}

@media all and (max-width: 400px) {
  .validationLabel {
    font-size: 12px !important;
  }
  #completePick .invoicesPrinted,
  #completePick .invoicesPrintedNoPadding {
    font-size: 16px;
  }
}

.input-hlder {
  margin: 0px 0;
  position: relative;
  display: inline-block;
}

.input-hlder span {
  padding: 10px;
  pointer-events: none;
  position: absolute;
  left: 20px;
  top: 15px;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
  font-size: 14px;
}
.input-hlder input {
  padding: 10px;
}

.input-hlder input:focus + span,
.input-hlder input:not(:placeholder-shown) + span {
  padding: 10px 10px 8px 5px;
  font-size: 16px;
  top: 9px;
  line-height: 10px;
  left: 15px !important;
  opacity: 1;
  transform: scale(0.75) translateY(-30%) translateX(-10px);
  background-color: #fff;
  color: $black;
}
.input-hlder input:focus + span.qty,
.input-hlder input:not(:placeholder-shown) + span.qty {
  left: 25px !important;
  color: $black;
}
/* For IE Browsers*/

.input-hlder span.qty {
  left: 16px !important;
}

.input-hlder input:focus + span,
.input-hlder input:not(:-ms-input-placeholder) + span {
  opacity: 1;
  transform: scale(0.75) translateY(-30%) translateX(-10px);
  background-color: #fff;
}

#errorWrapperCompletedPick {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}
