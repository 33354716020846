#app {
  .appBarRightSection {
    font-weight: 12px;
    color: $black;
    float: right;
  }

  #appBarMenuIcon {
    color: #000000;
  }
  @media all and (min-width: 481px) {
    .loggedSection{
      margin-bottom:4px;
    }
    .menuIcon {
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin-left: 29px;
    }
    .logo {
      width: 200px;
      cursor: pointer;
    }
  }

  @media all and (max-width: 480px) {
    .storeNumber{
      margin-top: 10px;
    }
    .loggedSection { white-space: nowrap; margin-bottom:6px; }
    .menuIcon {
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin-left: 0px;
    }
    .logo {
      width: 185px;
      cursor: pointer;
    }

    [class^="theme--leftIcon"],
    [class*=" theme--leftIcon"] {
      width: 17px;
      height: 17px;
      margin-top: -25px;
    }
  }

  [class^="theme--leftIcon"],
  [class*=" theme--leftIcon"] {
    width: 20px;
    height: 20px;
    margin-top: -20px;
  }
}

[class^="MuiBackdrop-root-"],
[class*=" MuiBackdrop-root-"] {
  background-color: transparent !important;
}
