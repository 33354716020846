@import "./assets/css/bootstrap/bootstrap.min.css";
@import "./assets/react-toolbox/theme.css";
@import "core-libs/scss/index.scss";
@import "components/styles/Ordering.scss";
@import "components/styles/HeaderComponent.scss";
@import "components/styles/ActiveViewOrders.scss";
@import "components/styles/NoInterNet.scss";
@import "components/styles/SkipItemValidation.scss";
@import "components/styles/ItemScanningRequired.scss";
@import "components/styles/TransferStore.scss";
@import "components/styles/ContinueStore.scss";
@import "components/styles/NoStore.scss";
@import "components/styles/ManualStoreNumber.scss";
@import "components/styles/CustomerNoteToStore.scss";
@import "components/styles/LoadingIndicator.scss";

@font-face {
  font-family: "AutoZone Heavy";
  src: url("./assets/fonts/AutoZoneCond-Heavy.eot");
  src: url("./assets/fonts/AutoZoneCond-Heavy.woff") format("woff"),
    url("./assets/fonts/AutoZoneCond-Heavy.svg#filename") format("svg");
}

@font-face {
  font-family: "AutoZone";
  src: url("./assets/fonts/AutoZoneCond.eot");
  src: url("./assets/fonts/AutoZoneCond.woff") format("woff"),
    url("./assets/fonts/AutoZoneCond.svg#filename") format("svg");
}

@font-face {
  font-family: "AutoZone Medium";
  src: url("./assets/fonts/AutoZoneCond-Medium.eot");
  src: url("./assets/fonts/AutoZoneCond-Medium.woff") format("woff"),
    url("./assets/fonts/AutoZoneCond-Medium.svg#filename") format("svg");
}

.text-style {
  font-family: "AutoZone Heavy";
  font-weight: 900;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
}

h1,
h2,
h3,
h4,
h5 {
  @extend .text-style;
}

h6 {
  @extend .text-style;
  height: 24px;
  font-size: 24px;
}

@media all and (min-width: 480px) {
  body {
    font-family: Arial, Helvetica, sans-serif;
    max-width: 1280px;
    width: 100%;
    margin: 0px auto;
    text-align: left;
    padding-right: 0px !important;
    overflow-y: auto !important;
  }
}

@media all and (max-width: 480px) {
  body {
    font-family: Arial, Helvetica, sans-serif;
    max-width: 1180px;
    width: 100%;
    margin: 0px auto;
    text-align: left;
    padding-right: 0px !important;
    #listGroup,
    #activeListSection,
    #skipValidation {
      height: auto;
      overflow-y: auto !important;
      overflow-x: hidden;
    }
  }
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

button:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* block ui */
.loading-indicator {
  text-align: center;
}
.loading-bullet {
  display: inline-block;
  opacity: 0;
  font-size: 2em;
  color: #02a17c;
}

/* CSS Animations */
@-webkit-keyframes fadeInRTL1 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
@keyframes fadeInRTL1 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeInRTL2 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  40% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
@keyframes fadeInRTL2 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  40% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeInRTL3 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
@keyframes fadeInRTL3 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
.loading-bullet {
  display: inline-block;
  opacity: 0;
  -webkit-animation: 3s ease 0.5s infinite fadeInRTL1;
  animation: 3s ease 0.5s infinite fadeInRTL1;
}
.loading-bullet + .loading-bullet {
  -webkit-animation-name: fadeInRTL2;
  animation-name: fadeInRTL2;
}
.loading-bullet + .loading-bullet + .loading-bullet {
  -webkit-animation-name: fadeInRTL3;
  animation-name: fadeInRTL3;
}

/* loader */
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

// React root
#app {
  flex-direction: column;
  display: flex;
  height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
