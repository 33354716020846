.btnWrapper {
  .btn {
    font-family: "AutoZone Heavy";
    padding: 0px;
    background: none;
  }
  .btn-primary {
    color: $white;
    background-color: $primary-orange;
    border-color: $primary-orange;
    text-transform: uppercase;
    border-radius: 0;
    padding: 6px 16px;
    font-size: 16px;
    box-shadow: none;
    &:disabled {
      color: $white;
      background-color: $light-orange;
      border-color: $light-orange;
      text-transform: uppercase;
    }
  }
  .btn-link {
    color: $link-orange;
    font-family: Arial;
  }
}

.underlined {
  button {
    text-decoration: underline;
  }
}
