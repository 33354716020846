#transferStoreDialog {
  .transferTitle {
    display: flex;
    flex-direction: row;
    background-color: #9b9b9b;
    color: $black;
    padding: 10px;
    margin-bottom: 16px;
    text-transform: uppercase;
    .transferText {
      justify-content: left;
      flex-grow: 1;
      font-family: "AutoZone Medium";
      padding-left: 6px;
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: condensed;
    }
    .closeBtn {
      color: $black;
      margin: 2px;
      font-size: 12px;
      font-weight: bold;
    }
    .closeIcon {
      width: 0.75em;
      font-weight: bold;
      margin-top: -3px;
      cursor: pointer;
    }
  }
  .transferBody {
    padding: 6px 16px 16px 16px;
    margin-bottom: 24px;
    color: $black;
    background-color: #e9e9e9;
    #bodyLine1 {
      display: flex;
      flex-direction: row;
      #bodyLabel {
        padding: 8px 0px 5px 0px;
        font-family: "AutoZone";
        font-stretch: condensed;
        font-size: 16px;
        font-weight: normal;
      }
      #bodyValue {
        color: #008300;
        font-size: 24px;
        font-weight: 500;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        padding-left: 6px;
      }
    }
    #bodyLine2 {
      display: flex;
      flex-direction: row;
      padding-bottom: 8px;
      padding-top: 6px;
      #bodyLabel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 208px;
      }
      #bodyValue {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
    #bodyLine3 {
      display: flex;
      flex-direction: row;
      #bodyLabel {
        text-transform: uppercase;
        width: 173px;
        height: 18px;
        font-family: "AutoZone Medium";
        font-size: 18px;
        font-weight: 500;
        font-stretch: condensed;
        width: 208px;
      }
      #bodyValue {
        font-family: "AutoZone Medium";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;

        .din1 {
          display: inline-block;
          width: 20px;
          width: 27px;
          text-align: center;
        }
      }
    }
  }
  .transferActionsWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px !important;
    .transferFulfilManually {
      button {
        color: $primary-orange !important;
        border: 1px solid $primary-orange;
        background-color: $white;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 6px 16px;
      }
    }
    .transferForward {
      button {
        color: $white !important;
        border: 1px solid $primary-orange;
        background-color: $primary-orange;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        padding: 6px 16px;
      }
    }
    button {
      text-transform: uppercase;
    }
  }
}
#transferItemError {
  margin-bottom: 60px;
  margin-top: -20px;
}

@media all and (max-width: 400px) {
  #transferStoreDialog {
    #bodyValue {
      font-size: 20px !important;
      padding-top: 3px;
    }
  }
}
