@media all and (max-width: 480px) {
  .dialogMainWrapper {
    position: absolute;
    top: 100px !important;
    background-color: rgb(255, 255, 255);
    border-radius: calc(0.2 * 10px);
    box-shadow: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
    display: flex;
    flex-direction: column;
    max-width: 96vw;
    opacity: 0;
    overflow: inherit;
    transform: translateY(calc(-1 * calc(4 * 10px)));
    transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: calc(0.35s / 5);
    opacity: 1;
    transform: translateY(0%);
    width: 448px !important;
    .skipTitle {
      height: 28px;
      color: rgb(0, 0, 0);
      flex-grow: 0;
      font-size: calc(2 * 8px);
      font-weight: 500;
      letter-spacing: 0.02em;
    }
    section {
      color: rgb(117, 117, 117);
      flex-grow: 2;
      padding: calc(1.6 * 10px);
    }
    section p {
      font-size: calc(1.4 * 10px);
      font-weight: 400;
      letter-spacing: 0;
      margin: 0;
    }
    .navigation {
      flex-grow: 0;
      padding: calc(0.8 * 10px);
      text-align: right;
    }
    .button {
      margin-left: calc(0.8 * 10px);
      min-width: 0;
      padding-left: calc(0.8 * 10px);
      padding-right: calc(0.8 * 10px);
    }
    .yesButton {
      button {
        border: 1px solid $primary-orange !important;
        background-color: $primary-orange !important;
        font-family: "AutoZone Heavy";
        text-transform: uppercase;
        border-radius: 0;
        font-size: 16px;
        font-weight: 900;
        margin-right: 0px;
        font-stretch: condensed;
        padding: 6px 37px !important;
        margin-bottom: 0px;
        font-stretch: normal;
        box-shadow: none;
      }
    }
    .skipBar {
      display: flex;
      flex-direction: row;
      background-color: #4278a0;
      color: $black;
      padding: 5px 0px 5px 0px;
      margin-bottom: 16px;
      .skipValLabel {
        font-family: "AutoZone Medium";
        font-size: 14px;
        font-stretch: condensed;
        color: $white;
      }
      .skipValLabelValue {
        font-family: "AutoZone Medium";
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
        margin: 5px;
      }
      .requestText {
        justify-content: left;
        flex-grow: 1;
        color: $white;
        margin-left: 16px;
      }
      .pickText {
        justify-content: left;
        flex-grow: 1;
        text-align: center;
      }
      .skippedText {
        justify-content: right;
        flex-grow: 1;
        text-align: right;
        margin-right: 11px;
      }
    }

    .confirmationBoxHeader {
      padding: 0px 0px 0px 0px;
    }
    .confirmationBoxText {
      padding: 16px 16px 0px 16px;
      font-size: 14px;
      color: black;
    }

    .skipBody {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;
      .formControl {
        margin: 0px 10px;
      }
      .skipPartLabel {
        margin-top: 7px;
        color: $black;
        font-size: 14px;
        margin: 5px;
      }
      input[type="number"] {
        margin: 0;
        display: block;
        width: 20%;
        height: calc(2.25rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $black;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #3a3c3d;
      }
      .skipQtySection {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: 48px;
      }
    }
    .skipAction {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 16px !important;
      .secondaryButton {
        button {
          color: $primary-orange !important;
          border: 1px solid $primary-orange;
          background-color: $white;
          font-size: 16px;
          font-weight: 900;
          font-family: "AutoZone Heavy";
          font-stretch: condensed;
          border-radius: 0;
          margin-right: 16px;
          padding: 6px 16px;
        }
      }
      .submitButtonModal {
        button {
          color: $white !important;
          border: 1px solid $primary-orange;
          background-color: $primary-orange;
          font-size: 14px;
          font-weight: 900;
          font-family: "AutoZone Medium";
          font-stretch: condensed;
          border-radius: 0;
          margin-right: 0px;
          padding: 8px 27px;
        }
      }
      button {
        text-transform: uppercase;
      }
    }

    .submitButton {
      button {
        border: 1px solid $primary-orange !important;
        background-color: $primary-orange !important;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Heavy";
        font-stretch: condensed;
        border-radius: 0px;
        padding: 4px 39px;
      }
    }
  }
}

@media all and (min-width: 481px) {
  div .dialogMainWrapper {
    top: 160px !important;
    position: absolute !important;
  }

  .yesButton {
    button {
      border: 1px solid $primary-orange !important;
      background-color: $primary-orange !important;
      font-family: "AutoZone Heavy";
      text-transform: uppercase;
      border-radius: 0;
      font-size: 16px;
      font-weight: 900;
      margin-right: 0px;
      font-stretch: condensed;
      padding: 6px 38px !important;
      margin-bottom: 0px;
      font-stretch: normal;
      box-shadow: none;
    }
  }

  .skipBar {
    display: flex;
    flex-direction: row;
    background-color: #4278a0;
    color: $black;
    padding: 5px 0px 5px 0px;
    margin-bottom: 16px;
    .skipValLabel {
      font-family: "AutoZone Medium";
      font-size: 14px;
      font-stretch: condensed;
      color: $white;
    }
    .skipValLabelValue {
      font-family: "AutoZone Medium";
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: condensed;
      line-height: normal;
      letter-spacing: normal;
      color: $white;
      margin: 5px;
    }
    .requestText {
      justify-content: left;
      flex-grow: 1;
      color: $white;
      margin-left: 16px;
    }
    .pickText {
      justify-content: left;
      flex-grow: 1;
      text-align: center;
    }
    .skippedText {
      justify-content: right;
      flex-grow: 1;
      text-align: right;
      margin-right: 11px;
    }
  }

  .skipBody {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    .formControl {
      margin: 0px 10px;
    }
    .skipPartLabel {
      margin-top: 7px;
      color: $black;
      font-size: 14px;
      margin: 5px;
    }
    input[type="number"] {
      margin: 0;
      display: block;
      width: 20%;
      height: calc(2.25rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $black;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #3a3c3d;
    }
    .skipQtySection {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-left: 48px;
    }
  }

  .skipAction {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 16px !important;
    .secondaryButton {
      button {
        color: $primary-orange !important;
        border: 1px solid $primary-orange;
        background-color: $white;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 7px 18px;
      }
    }
    .noSecondaryButton {
      button {
        color: $black !important;
        border: 1px solid $black;
        background-color: $white;
        font-size: 16px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 16px;
        padding: 6px 40px;
        margin-top: 0;
      }
    }

    .submitButtonModal {
      button {
        color: $white !important;
        border: 1px solid $primary-orange;
        background-color: $primary-orange;
        font-size: 14px;
        font-weight: 900;
        font-family: "AutoZone Medium";
        font-stretch: condensed;
        border-radius: 0;
        margin-right: 0px;
        padding: 8px 27px;
      }
    }
    button {
      text-transform: uppercase;
    }
  }

  .skipTitle {
    display: flex;
    flex-direction: row;
    background-color: #9b9b9b;
    color: $black;
    margin-bottom: 0px;
    text-transform: uppercase;

    .skipText {
      width: 86%;
      font-family: "AutoZone Heavy";
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: condensed;
      letter-spacing: normal;
      color: $black;
      margin-left: 16px;
    }

    .closeBtn {
      height: 14px;
      font-family: Arial;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $black;
      margin-right: 10px;
      vertical-align: middle;
      margin-top: 2px;
    }
    .closeIcon {
      width: 0.75em;
      font-weight: bold;
      margin-top: -3px;
      cursor: pointer;
    }
    .closeI {
      margin-right: 16px;
    }
  }

  .dialogMainWrapper {
    position: static;
    top: 0px;
    width: 620px !important;
    .skipTitle {
      width: 100%;
      height: 28px;
      color: rgb(0, 0, 0);
      flex-grow: 0;
      font-size: calc(2 * 10px);
      font-weight: 500;
      letter-spacing: normal;
      .skipText {
        width: 94%;
      }
    }
    section {
      color: rgb(117, 117, 117);
      flex-grow: 2;
      padding: calc(1.6 * 10px);
      background: $white;
    }
    section p {
      font-size: calc(1.4 * 10px);
      font-weight: 400;
      letter-spacing: 0;
      margin: 0;
    }
    .navigation {
      flex-grow: 0;
      padding: calc(0.8 * 10px);
      text-align: right;
    }
    .button {
      margin-left: calc(0.8 * 10px);
      min-width: 0;
      padding-left: calc(0.8 * 10px);
      padding-right: calc(0.8 * 10px);
    }
    .confirmationBoxHeader {
      padding: 0px 6px 6px 0px;
    }
    .confirmationBoxText {
      padding: 16px 16px 0px 16px;
      font-size: 14px;
      color: black;
    }
  }
}

div[class^="theme--wrapper--"] {
  left: 0;
}
